import React from "react";
import { getMsgDetail } from "services";
import { BaseLayout, Loading } from "components";
import styles from "./styles.module.scss";
import { isNative, nativeHref } from 'utils';
import queryString from 'query-string';

const _params = queryString.parse(window.location.search);


class GuideDetail extends React.PureComponent {
  state = {
    loading: true,
  };
  componentDidMount() {
    const { history, match } = this.props;
    const { params } = match;
    document.title = "消息详情";

    if (!params.id || !Number.isInteger(parseInt(params.id))) {
      history.goBack();
    }
    this.getDetail();
    setTimeout(() => {
      const goNativeEle = document.getElementById('goNative');
      goNativeEle && goNativeEle.addEventListener('click', () => {
        nativeHref(this.state.clickType || '');
      });

      // 改动文章模版 
      const _tables = document.getElementsByTagName('table');
      // console.log(_tables);
      [..._tables].forEach(element => {
        element.style.tableLayout = 'auto';
      });

    }, 500);

  }
  getDetail = async () => {
    const { match } = this.props;
    const { params } = match;
    const customerNo = _params.customerNo || '';
    const res = await getMsgDetail(params.id, customerNo);
    if (res.code === '0') {
      this.setState({
        ...res.data
      });
    }

    this.setState({
      loading: false
    })
  };

  // 返回
  onBack = () => {
    window.history.back();
  }


  render() {
    const { loading, title, content, clickType = '', clickUrl = '' } = this.state;
    // 跳转H5地址
    const h5TypeConfig = {
      'startLearn': '开始学习',
      'toInvitation': '去邀请',
      'activityDetail': '查看活动详情',
    }

    // 和app 交互
    const appTypeConfig = {
      'deposit': '去充值',
      'toTrade': '去交易',
      'customer': '在线客服',
      'accountPrivilege': '查看账户权益',
    }

    let _content = content;

    // 拼接地址
    if (h5TypeConfig[clickType]) {

      const url = queryString.stringifyUrl({ url: clickUrl, query: { ..._params } });

      _content = `${content}<a style="color: #FF7401;" href=${url}>${h5TypeConfig[clickType] || ''}</a>`;

    } else if (appTypeConfig[clickType] && isNative) {

      _content = `${content}<a id="goNative" style="color: #FF7401;" >${appTypeConfig[clickType] || ''}</a>`;

    }

    return (
      <BaseLayout>
        {loading ? (
          <Loading />
        ) : (
            <section className={styles.detail_new}>

              {
                isNative ? null :
                  <header className={styles.header}>
                    <div className={styles.back} onClick={this.onBack} />
                    <div className={styles.title}>消息详情</div>
                  </header>
              }
              {
                title && <div className={styles.content}>
                  <div className={styles.detail}>
                    <div className={styles.detail_header}>
                      <span className={styles.title}>{title}</span>
                    </div>
                    <div
                      className={styles.detail_content}
                      dangerouslySetInnerHTML={{ __html: _content }}
                    >
                    </div>

                  </div>
                </div>
              }
            </section>
          )}
      </BaseLayout>
    );
  }
}
export default GuideDetail;
